.wrapper {
  padding-block-start: var(--bui_spacing_3x);

  .selection {
    cursor: pointer;
    padding: var(--bui_spacing_3x) 0 !important;

    &:hover {
      background-color: transparent !important;
    }
  }
}
