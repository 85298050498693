.totalPriceLabel {
  color: var(--bui_color_foreground_alt);
}

.mainPrice {
  white-space: nowrap;
  padding-block-start: var(--bui_spacing_half);
}

[data-device="mdot"] .mainPrice {
  padding-block-start: unset;
}

.priceContainer {
  display: inline-flex;
}

.groupPriceLabel {
  color: var(--bui_color_foreground_alt);
}

.groupPrice {
  color: var(--bui_color_foreground);
}

.groupPriceText {
  display: inline;
}
