.wrapper {
  display: inline-block;
}

.wrapper > span {
  border-color: var(--bui_color_border) !important;
}

.wrapperWide {
  width: 100%;
}
