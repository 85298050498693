.baggagePolicies {
  @apply --bui_font_small_1;
  color: var(--bui_color_foreground_alt);
}

.modalOpener {
  @apply --bui_font_small_1;
  vertical-align: baseline;
  margin-inline-start: calc(var(--bui_spacing_1x) * -1);
}
