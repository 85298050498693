.wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.removeWrapper {
  grid-column: 2/2;
  grid-row: 10/-1;
  display: grid;
  align-content: start;
}

.remove {
  padding: var(--bui_spacing_1x) !important;
  height: var(--bui_spacing_8x) !important;
  width: var(--bui_spacing_8x) !important;
  box-sizing: content-box;
}
