.timeDateBlock {
  width: 70px;
  white-space: nowrap;

  .iataAndDate {
    color: var(--bui_color_foreground_alt);

    .iataDateSeparator {
      white-space: pre;
    }

    .iataWarning {
      background: var(--bui_color_accent_background_alt);
      border-radius: var(--bui_border_radius_100);
      padding: var(--bui_spacing_half) var(--bui_spacing_half);
      margin: calc(var(--bui_spacing_half) * -1);
    }
  }
}

.stopsRoot {
  flex: 1;
  padding-inline-start: var(--bui_spacing_6x);
  padding-inline-end: var(--bui_spacing_6x);

  .stopsWrap {
    position: relative;
    width: 100%;
    text-align: center;

    .stopsConnector {
      position: absolute;
      border-block-start: 1px solid var(--bui_color_foreground);
      width: 100%;
      inset-inline-start: 0;
      inset-block-start: 50%;
      z-index: 1;

      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 9px;
        height: 9px;
        border: 1px solid var(--bui_color_foreground);
        border-radius: var(--bui_border_radius_rounded);
        inset-block-start: -5px;
      }

      &::before {
        inset-inline-start: -9px;
      }

      &::after {
        inset-inline-end: -9px;
      }
    }

    .stopsBadge {
      position: relative;
      z-index: 2;
      margin: 0 var(--bui_spacing_1x);
    }
  }
}
