.wrapper {
  display: grid;
  grid-template-columns: 1fr 0fr;
}

.inputWrapper {
  display: grid;
}

.flipWrapper {
  display: grid;
  align-content: center;
}

.flipBtn {
  padding: var(--bui_spacing_1x) !important;
  width: var(--bui_spacing_8x) !important;
  height: var(--bui_spacing_8x) !important;
  box-sizing: content-box;
}

.flipBtnWrapper {
  transform: rotate(90deg);
}

.errorWrapper {
  padding: var(--bui_spacing_2x);
}
