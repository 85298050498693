.wrapper {
  line-height: 0;
}

.input {
  display: inline-block;
  width: auto;
}

.inputDark label > span:nth-child(2) {
  background-color: var(--bui_color_brand_primary_background) !important;
  border: var(--bui_border_width_200) solid var(--bui_color_white) !important;
}

.newInputChecked label > span:nth-child(2) {
  background: var(--bui_color_background_base) !important;
  border-color: var(--bui_color_white) !important;
}

.newInputChecked label > span:nth-child(2) svg {
  fill: var(--bui_color_brand_primary_background);
}

.newInputChecked label > span:nth-child(2):focus {
  border-color: var(--bui_color_accent_background);
}

.focusedCheckbox > label > span:nth-child(2),
.newInputChecked label > span:nth-child(2):focus {
  box-shadow: none !important;
  border-color: var(--bui_color_accent_background) !important;
}
