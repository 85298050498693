.root {
  display: flex;
}

.root a {
  text-decoration: none;
  color: var(--bui_color_action_foreground);
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

.iconWrapper {
  width: var(--bui_spacing_6x);
  height: var(--bui_spacing_6x);
}

.tail {
  padding-block-start: var(--bui_spacing_2x);
  padding-inline-start: calc(var(--bui_spacing_1x) * 10);
}

.noWrap {
  white-space: nowrap;
}
