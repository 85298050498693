.loading {
  margin-block-start: 25%;
  text-align: center;
}

.searchResults {
  position: relative;
  z-index: 0;
}

.paginationItem {
  display: inline-block;
  cursor: pointer;
  padding-inline-start: var(--bui_spacing_2x);
  padding-inline-end: var(--bui_spacing_2x);
}

.noResults {
  @apply --bui_font_emphasized_2;
  text-align: center;
  color: var(--bui_color_foreground_alt);
  margin: var(--bui_spacing_4x);
}

.searchRemoveIcon {
  margin-block-end: var(--bui_spacing_4x);
}

.stickyTop {
  position: sticky;
  inset-block-start: 0px;
  max-height: calc(100vh - var(--bui_spacing_1x));
  overflow-x: hidden;
  overflow-y: scroll;
  padding: var(--bui_spacing_4x) 0;
  margin-block-end: var(--bui_spacing_4x);
  padding-inline-end: var(--bui_spacing_8x);
}

.stickyTop > div:last-child {
  padding-block-end: 0px;
}

.searchResultsList {
  margin-block-end: var(--bui_spacing_4x);
}