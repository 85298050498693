.btn {
  min-height: calc(var(--bui_spacing_8x) + var(--bui_spacing_4x));
}

.btn,
.btn:active,
.btn:focus {
  cursor: pointer;
  background-color: var(--bui_color_background_elevation_one);
  border-radius: var(--bui_border_radius_100);
  border-style: solid;
  border-width: var(--bui_border_width_100);
  border-color: var(--bui_color_background_elevation_one);
  box-sizing: border-box;
  outline: none;
  padding: var(--bui_spacing_2x);
  text-align: start;
  width: 100%;
}

.btn:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--bui_color_action_border);
  position: relative;
}

[data-bui-keyboard] .btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base),
    0 0 0 4px var(--bui_color_action_border);
  z-index: 2;
}

.btnLarge {
  min-height: calc(var(--bui_spacing_8x) + var(--bui_spacing_6x)) !important;
}

.btnError {
  border-style: solid;
  border-width: var(--bui_border_width_100);
  border-color: var(--bui_color_destructive_border);
}

.inner {
  display: flex;
  gap: var(--bui_spacing_2x);
}

.prefix {
  grid-column: -3;
  display: inline-block;
}

.suffix {
  grid-column: 2;
  display: inline-block;
}

.content {
  display: grid;
  width: 100%;
  position: relative;
}

.contentInner {
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
