.buttons {
  margin: 0 var(--bui_spacing_4x) var(--bui_spacing_2x);
}

.divider {
  margin: var(--bui_spacing_2x) calc(var(--bui_spacing_4x) * -1) !important;
  color: var(--bui_color_foreground_disabled);
}

.segment {
  margin-block-start: var(--bui_spacing_2x);
}

.segment:first-child {
  margin-block-start: 0;
}

.button {
  width: 100%;
  padding: 0;
  margin: 0;
}

.hidden {
  visibility: hidden;
}

.outboundButtonCard {
  display: block;
}

.outboundButtonCard:focus {
  z-index: 0;
}

.card {
  width: 100%;
}

.priceBlock {
  width: 100%;
}

.luggage {
  flex: 1 0 40%;
  padding-inline-end: var(--bui_spacing_3x);
}

.price {
  flex: 1 1 auto;
  text-align: end;
}

.arrow {
  transform: rotate(180deg);
}

.fakeButton {
  margin-block-start: var(--bui_spacing_4x);
  width: 100%;
}

.cardShadow {
  box-shadow: var(--bui_shadow_100);
}

.badges {
  margin-block-end: var(--bui_spacing_2x);
}

.geniusBadge {
  display: inline-flex;
  vertical-align: middle;
  height: 22px;
}

.geniusBadge > svg {
  height: 100%;
}

.highlightFirefly {
  border-color: var(--bui_color_brand_primary_background);
  border-width: 2px;
}
