.calendar {
  max-height: 100% !important;
}

.footer {
  display: grid;
  grid-gap: var(--bui_spacing_4x);
  align-items: center;
  justify-items: center;
}

.modalFix {
  max-width: 100% !important;
}
