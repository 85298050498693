.wrapper {
  padding: var(--bui_spacing_3x);
  display: flex;
  flex-direction: row;
  gap: var(--bui_spacing_4x);
  border: solid var(--bui_border_width_100) var(--bui_color_action_border);
  border-radius: var(--bui_border_radius_100);
}

.body {
  flex: 1 1 1px;
}

.link,
.link:active,
.link:visited {
  text-decoration: none;
}
