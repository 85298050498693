.wrapper {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

[data-bui-keyboard] .wrapperFocus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
  border-radius: var(--bui_border_radius_100);
}

.wrapperWide {
  width: 100%;
}

.select {
  position: absolute;
  opacity: 0;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-block-end: 0px;
  inset-inline-start: 0px;
}

.text {
  width: 100%;
}

.customIcon {
  display: inline-block;
  padding-inline-start: var(--bui_spacing_2x);
}

[data-bui-keyboard] .wrapperFocus.wrapperDark {
  box-shadow: none;
  outline-style: solid;
  outline-color: var(--bui_color_accent_background);
  outline-offset: 2px;
}
