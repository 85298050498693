.listItemMoney {
  @apply --bui_font_emphasized_2;
  white-space: nowrap;
  padding-block-end: var(--bui_spacing_1x);
  color: var(--bui_color_foreground);
}

.textColor {
  @apply --bui_font_emphasized_2;
  color: var(--bui_color_foreground);
}
