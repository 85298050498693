/* stylelint-disable-next-line bui-optimal-typography/validate-typography */
.button {
  border: none;
  background-color: var(--bui_color_background_elevation_one);
  height: 100%;
  padding: var(--bui_spacing_1x);
  margin: 0px;
  vertical-align: middle;
  text-align: center;
  font-size: 0px;
  line-height: 0;
  display: inline-block;
  outline: none;
  cursor: pointer;
  z-index: 0;
  border-radius: var(--bui_border_radius_100);
}

[data-bui-keyboard] .button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
  border-radius: var(--bui_border_radius_100);
  z-index: 3;
}

.button:disabled > * {
  opacity: 0.5;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}
