.modalHeader {
  padding: var(--bui_spacing_4x);
  padding-block-end: var(--bui_spacing_2x);
}

.modalHeaderTitle {
  @apply --bui_font_headline_3;
}

.modalHeaderSubtitle {
  @apply --bui_font_body_2;
  color: var(--bui_color_foreground_alt);
}

.timeLineContainer {
  padding-block-end: var(--bui_spacing_4x);
}

.timeline {
  padding-inline-end: var(--bui_spacing_4x);
  padding-block-end: var(--bui_spacing_6x);
  padding-inline-start: var(--bui_spacing_4x);
}

.timeLineItem {
  @apply --bui_font_body_2;
}

.contentContainer {
  border: none;
  padding-block-end: var(--bui_spacing_6x);
}

.contentTitle {
  @apply --bui_font_strong_1;
}

.contentItemList {
  padding-inline-start: 0;
}

.contentItemBullet {
  @apply --bui_font_body_1;
  margin-inline-start: var(--bui_spacing_6x);
}

.contentItemParagraph {
  @apply --bui_font_body_1;
}
