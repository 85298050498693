
.point__text {
    white-space: nowrap;
    display: inline-block;
}

.point__highlight {
    display: inline-block;
    background-color: var(--bui_color_accent_background_alt);
}

.iconContainer {
  position: relative;
  width: 28px;
  height: 24px;
}

.checkmarkIcon {
  position: absolute;
  left: 16px;
  top: 12px;
  background-color: white;
  border-radius: 50%;
}