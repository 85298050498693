.wrapper {
  display: flex;
  align-items: flex-start;
  gap: var(--bui_spacing_2x);
}

.wrapper [data-ui-only-this] {
  display: none;
  cursor: pointer;
}

.wrapper:hover [data-ui-only-this] {
  display: inline-block;
}
