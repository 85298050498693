.wrapper {
  display: block;
  width: 100%;
}

.line {
  width: 100%;
}

.line + .line {
  margin-block-start: var(--bui_spacing_2x);
}

.yellowInner {
  display: grid;
  grid-template-rows: 1fr;
  align-content: stretch;
  grid-gap: var(--bui_spacing_1x);
}
