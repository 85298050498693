.inline {
  display: block;
  color: var(--bui_color_destructive_foreground);
}

.root {
  position: absolute;
  width: 0px;
  height: 0px;
}

.rootTop {
  inset-block-start: 0px;
  inset-inline-start: 0px;
}

.rootBottom {
  inset-block-end: 0px;
  inset-inline-start: 0px;
}

.wrapper {
  position: absolute;
  z-index: 1;
}

.wrapper::after {
  content: " ";
  transform: translateX(-50%) translateY(-50%) rotate(-135deg);
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: var(--bui_color_destructive_background);
}

.wrapperTop {
  inset-block-start: 0px;
  inset-inline-start: 0px;
  padding-block-end: var(--bui_spacing_2x);
  transform: translate(0, -100%);
}

.wrapperTop::after {
  inset-inline-start: var(--bui_spacing_3x);
  inset-block-end: 1px;
}

.wrapperBottom {
  inset-block-end: 0px;
  inset-inline-start: 0px;
  transform: translate(0, 100%);
  padding-block-start: var(--bui_spacing_2x);
}

.wrapperBottom::after {
  inset-inline-start: var(--bui_spacing_3x);
  inset-block-start: var(--bui_spacing_2x);
}

.wrapperRight {
  transform: translate(0px, -3px);
  margin-inline-start: var(--bui_spacing_4x);
}

.wrapperRight::before {
  content: " ";
  transform: translateY(-50%) rotate(-45deg);
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: var(--bui_color_destructive_background);
  inset-inline-start: -4px;
  inset-block-start: 50%;
}

.content {
  padding: var(--bui_spacing_1x) var(--bui_spacing_2x);
  background-color: var(--bui_color_destructive_background);
  color: var(--bui_color_foreground_inverted);
  border-radius: var(--bui_border_radius_100);
  white-space: nowrap;
}
