.message {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0px;

  /* stylelint-disable-next-line bui-strict-values/validate-values -- compensate the size */
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0px;
}
