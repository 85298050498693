.flipButton {
  border: none;
  background-color: var(--bui_color_background_elevation_one);
  padding: var(--bui_spacing_1x);
  cursor: pointer;
  border-radius: var(--bui_border_radius_100);
  display: flex;
  align-items: center;
  justify-content: center;
}

.flipButton [data-button-flip-origin-destination] {
  transition-duration: 0.33s;
  transition-property: transform;
  transform: rotate(0deg);
}

.flipIcon {
  margin: var(--bui_spacing_half);
  border-radius: 100%;
}

.flipButtonIcon {
  transform: rotate(180deg) !important;
}
