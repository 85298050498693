.mainColumn {
  flex: 1;
}

.sideColumn {
  width: 250px;
}

.departureHolder {
  position: relative;
  padding-inline-start: var(--bui_spacing_8x);
  padding-block-end: var(--bui_spacing_4x);
}

.arrivalHolder {
  padding-inline-start: var(--bui_spacing_8x);
  padding-block-end: var(--bui_spacing_2x);
}

.connector {
  position: absolute;
  width: 0;
  height: 100%;
  inset-block-start: var(--bui_spacing_2x);
  margin-block-start: var(--bui_spacing_half);
  inset-inline-start: calc(var(--bui_spacing_4x) / 2);
  border-inline-start: 1px solid var(--bui_color_foreground);
}

.connector::before,
.connector::after {
  content: "";
  position: absolute;
  box-sizing: content-box;
  width: var(--bui_spacing_4x);
  height: var(--bui_spacing_4x);
  border-radius: var(--bui_border_radius_rounded);
  box-shadow: inset 0 0 0 1.5px var(--bui_color_foreground_alt);
  border: var(--bui_spacing_2x) solid var(--bui_color_transparent);
  background: var(--bui_color_background_base);
  inset-inline-start: calc(var(--bui_spacing_4x) * -1);
}

.connector::before {
  inset-block-start: calc(var(--bui_spacing_4x) * -1);
}

.connector::after {
  inset-block-end: calc(var(--bui_spacing_4x) * -1);
}
