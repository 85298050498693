.wrapper {
  display: block;
  width: 100%;
}

.line {
  width: 100%;
}

.line + .line {
  margin-block-start: var(--bui_spacing_4x);
}

.line.topLineWrapper + .line {
  margin-block-start: 0;
}

.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--bui_spacing_4x);
}

.topLineWrapper {
  background-color: var(--bui_color_accent_background);
  border-radius: var(--bui_border_radius_200);
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.topLine {
  background-color: var(--bui_color_white);
  border: var(--bui_spacing_1x) solid var(--bui_color_accent_background);
  border-block-end: none;
  border-radius: var(--bui_border_radius_200);
  border-end-start-radius: 6px; /* stylelint-disable-line */
  border-end-end-radius: 6px; /* stylelint-disable-line */
  padding: 14px var(--bui_spacing_4x); /* stylelint-disable-line */
}

.bottomMulti {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.bottom {
  width: 100%;
}

.yellowInner {
  display: grid;
  grid-template-columns: 1fr;
  align-content: stretch;
  grid-gap: 0 var(--bui_spacing_1x);
}

.yellowInner > :last-child:not(:first-child) {
  grid-column: 2;
}
