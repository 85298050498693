.searchHeader {
  background-color: var(--bui_color_white);
}

.background {
  position: absolute;
  inset-block-start: 0;
  width: 100%;
  height: calc((100% + var(--bui_spacing_4x))/2);
  background-color: var(--bui_color_brand_primary_background_dynamic);
  will-change: transform;
}

.minifiedSearchBox {
  position: relative;
  padding-block-start: var(--bui_spacing_4x);
}

/* @start flights_mdotvalue_sr_sticky_search_box */
.searchHeaderSticky {
  position: sticky;
  inset-block-start: 0;
  z-index: var(--bui_z_index_3);
  transition: box-shadow var(--bui_animation_fade_in);
}

.searchHeaderIsStuck {
  box-shadow: var(--bui_shadow_100);
}

/* @end flights_mdotvalue_sr_sticky_search_box */
