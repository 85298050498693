.bannerIconHolder {
  padding: var(--bui_spacing_2x);
  border-radius: var(--bui_border_radius_200);
  background: var(--bui_color_action_background_alt);
}

.cardTitle {
  margin-block-end: 0;
}

.cardSubtitle {
  color: var(--bui_color_foreground_alt);
  margin-block-end: var(--bui_spacing_2x);
}

.modalContent {
  padding-block-start: var(--bui_spacing_2x);
}
