.wrapper {
  width: 100%;
}

.title {
  margin-block-start: var(--bui_spacing_1x);
}

.description {
  margin: var(--bui_spacing_8x);
}

.contentDescription {
  margin-inline-start: calc(var(--bui_spacing_1x) * -10);
}

.terms {
  margin-block-start: var(--bui_spacing_2x);
}

.termsList {
  /* stylelint-disable-next-line bui-optimal-typography/validate-typography */
  line-height: var(--bui_spacing_8x);
}

.modalWrapper {
  border: none;
}

.link {
  color: var(--bui_color_cta_background);
  cursor: pointer;
}
