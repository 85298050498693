.featureWrapper {
  position: relative;
}

.infoIcon {
  margin-inline-start: 0;

  /* stylelint-disable-next-line bui-optimal-typography/validate-typography */
  line-height: 1;
}

.featureContent {
  flex: 1;
  margin-inline-start: var(--bui_spacing_3x);
}
