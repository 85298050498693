.rootDesktop {
  padding: var(--bui_spacing_6x) 0 var(--bui_spacing_2x);
}

.rootMobile {
  padding: var(--bui_spacing_4x) 0 var(--bui_spacing_6x);
}

.text {
  margin-inline-start: var(--bui_spacing_2x);
}
