.root {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--bui_color_border_alt);
  border-radius: var(--bui_border_radius_100);
  background: var(--bui_color_background_base);
}

.mobileRoot {
  background: var(--bui_color_background_base);
  padding: 0px;
}

.listItem {
  padding-block-start: var(--bui_spacing_4x);
  text-align: end;
}

.listItemMobile {
  padding: 0;
  padding-inline-end: var(--bui_spacing_4x);
  padding-block-end: var(--bui_spacing_4x);
  text-align: end;
  flex-grow: 1;
}

.headerItem {
  background-color: var(--bui_color_background_base_alt);
  padding-block-end: var(--bui_spacing_4x);
}

.itemsParent > * {
  padding: var(--bui_spacing_4x) var(--bui_spacing_4x) 0;
}

.itemsParent > *:last-child {
  padding-block-end: var(--bui_spacing_4x);
}

.grayscaleText {
  color: var(--bui_color_foreground_alt);
}

.discountAlert {
  display: inline-block;
}
