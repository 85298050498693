.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
}

.appShell {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
