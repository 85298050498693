.wrapper select + span {
  margin-inline-end: unset;
  margin-inline-start: calc(0 - var(--bui_spacing_2x));
  padding-inline-start: unset;
  padding-inline-end: var(--bui_spacing_2x);
}

[dir="rtl"] .wrapper select + span {
  margin-inline-start: unset;
  margin-inline-end: calc(0 - var(--bui_spacing_2x));
  padding-inline-end: unset;
  padding-inline-start: var(--bui_spacing_2x);
}
