.wrapper {
  border-radius: var(--bui_border_radius_100);
  background-color: var(--bui_color_background_elevation_one);
  padding: var(--bui_spacing_2x);
  grid-gap: var(--bui_spacing_2x);
  display: grid;
}

.row {
  display: grid;
  grid-gap: var(--bui_spacing_2x);
  grid-template-columns: 1fr;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: block;
}