.card {
  height: 100%;
}

.title {
  margin-block-end: var(--bui_spacing_4x);
}

.fareFeaturesIcons {
  margin-block-end: var(--bui_spacing_4x);
}

.button {
  margin-block-start: var(--bui_spacing_4x);
  height: 36px;
  border-radius: var(--bui_border_radius_100);
  padding-block-start: var(--bui_spacing_1x);
  width: 100%;
}

.btnPadding {
  margin-block-start: var(--bui_spacing_2x);
}

.popoverTrigger {
  width: 100%;
  height: 100%;
}

.popoverContent {
  width: 240px;
}

.ariaInvisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;

  /* stylelint-disable-next-line bui-strict-values/validate-values -- compensate for the size */
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
