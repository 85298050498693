.wrapper {
  width: 100%;
  display: block;
  padding: var(--bui_spacing_1x);
  background-color: var(--bui_color_accent_background);
  border-radius: var(--bui_border_radius_200);
  box-sizing: border-box;
  position: relative;
}

.newWrapper {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.wrapperShadow {
  box-shadow: var(--bui_shadow_100);
}
