.stopsRoot {
  justify-content: space-between;

  .stopsWrapper {
    position: relative;
    text-align: center;

    .stopsConnector {
      position: absolute;
      width: 140px;
      inset-inline-start: 40%;
      z-index: 1;
    }
  }
}

.singleLogo {
  width: 44px;
  text-align: left;
  display: block;
  background-size: contain;
  overflow: hidden;
}

.multipleLogos {
  width: 36px;
  margin-right: 8px;
}