.wrapper {
  width: 100%;
}

.genius {
  gap: 8px;
}

.genius svg {
  height: 20px;
}

.text {
  margin-inline-start: var(--bui_spacing_half);
}

.illustration {
  padding: 0 var(--bui_spacing_4x);
}

/* stylelint-disable-next-line bui-media-queries/validate-media */
@media (max-width: 375px) {
  .illustration {
    display: none;
  }
}
