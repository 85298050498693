.fixedWidth {
  width: var(--bui_spacing_6x);
}

.featureIconWrapper {
  display: inline-block;
  position: relative;
}

.availabilityIcon {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  inset-block-end: 0;
  inset-inline-start: var(--bui_spacing_3x);
}

.iconWithAvailability {
  margin-inline-end: var(--bui_spacing_1x);
}
