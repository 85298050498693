/* stylelint-disable -- disabled for the entire file due to a completely custom implementation */

/* Color Palette */
:root {
  --cx-primary-color: #0071c2;
  --cx-alert-color: #c00;
  --cx-success-color: #008009;
  --cx-bg-color-1: #fff;
  --cx-bg-color-2: #383838;
  --cx-bg-color-3: #949494;
  --cx-bg-color-4: #ededed;
  --cx-bg-color-5: #003580;
  --cx-bg-color-6: #d9d9d9;
  --cx-txt-color-1: #262626;
  --cx-txt-color-2: #6b6b6b;
  --cx-txt-color-3: #fff;
  --cx-txt-color-4: #949494;
  --cx-icon-color-1: #8c8c8c;
  --cx-icon-color-2: #000;
  --cx-icon-color-3: #6b6b6b;
  --cx-border-color-1: #e7e7e7;
  --cx-border-color-2: #949494;
  --cx-outline-color-1: #000;
}

.cx-widget.cx-theme-booking {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: var(--cx-txt-color-1);
  background-color: var(--cx-bg-color-1);
  scrollbar-face-color: var(--cx-bg-color-3);
  scrollbar-track-color: var(--cx-bg-color-3);
  scrollbar-arrow-color: var(--cx-bg-color-3);
}

.cx-widget.cx-theme-booking h2.cx-title {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.cx-widget.cx-theme-booking.cx-window-manager {
  background-color: var(--cx-bg-color-5);
  height: 100vh;
}

.cx-widget.cx-theme-booking .cx-theme {
  background-color: var(--cx-bg-color-1);
  color: var(--cx-txt-color-1);
  border-color: var(--cx-border-color-1) !important;
}

.cx-widget.cx-theme-booking .cx-overlay {
  background-color: var(--cx-bg-color-1);
}

.cx-widget.cx-theme-booking .cx-svg-icon-tone1 {
  fill: var(--cx-primary-color);
}

.cx-widget.cx-theme-booking .cx-svg-icon-tone2 {
  fill: var(--cx-icon-color-1);
}

.cx-widget.cx-theme-booking .cx-svg-icon-shadow1 {
  fill: var(--cx-icon-color-2);
  opacity: 0.2;
}

.cx-widget.cx-theme-booking .cx-svg-icon-shadow2 {
  fill: var(--cx-icon-color-2);
  opacity: 0.1;
}

.cx-widget.cx-theme-booking * {
  border-color: var(--cx-border-color-1);
}

.cx-widget.cx-theme-booking .cx-titlebar .cx-title {
  color: var(--cx-txt-color-1);
}

.cx-widget.cx-theme-booking .cx-buttons-window-control .cx-svg-icon-tone1 {
  fill: var(--cx-icon-color-3);
}

.cx-widget.cx-theme-booking .cx-input-icon-overlay .cx-svg-icon-tone1 {
  fill: var(--cx-primary-color);
}

.cx-widget.cx-theme-booking label {
  color: var(--cx-txt-color-1);
}

.cx-widget.cx-theme-booking a {
  color: var(--cx-primary-color);
}

.cx-widget.cx-theme-booking a:hover {
  color: var(--cx-txt-color-1);
}

.cx-widget.cx-theme-booking .cx-icon-alert-circle {
  color: var(--cx-alert-color);
}

.cx-widget.cx-theme-booking .cx-footer,
.cx-widget.cx-theme-booking .cx-branding {
  color: var(--cx-txt-color-2);
}

.cx-widget.cx-theme-booking .cx-footer *,
.cx-widget.cx-theme-booking .cx-branding * {
  fill: var(--cx-txt-color-2);
}

.cx-widget.cx-theme-booking .cx-form-control {
  background-color: var(--cx-bg-color-1);
  color: var(--cx-txt-color-1);
}

.cx-widget.cx-theme-booking .cx-form-control.cx-error {
  border-color: var(--cx-alert-color) !important;
}

.cx-widget.cx-theme-booking .cx-form-control::placeholder {
  color: var(--cx-txt-color-2);
}

.cx-widget.cxbooking .cxcontrol:-moz-placeholder {
  color: var(--cx-txt-color-2);
}

.cx-widget.cxbooking .cxcontrol::-moz-placeholder {
  color: var(--cx-txt-color-2);
}

.cx-widget.cxbooking .cxcontrol:-ms-input-placeholder {
  color: var(--cx-txt-color-2);
}

.cx-widget.cxbooking .cxcontrol::-webkit-input-placeholder {
  color: var(--cx-txt-color-2);
}

.cx-widget.cx-theme-booking .cx-btn:focus {
  border-width: 1px;
}

.cx-widget.cx-theme-booking input:focus,
.cx-widget.cx-theme-booking textarea:focus,
.cx-widget.cx-theme-booking .cx-btn:focus,
.cx-widget.cx-theme-booking .cx-button-group button:focus,
.cx-widget.cx-theme-booking .cx-form-control:not(.cx-error):focus {
  border-color: var(--cx-primary-color) !important;
}

.cx-widget.cx-theme-booking input,
.cx-widget.cx-theme-booking select,
.cx-widget.cx-theme-booking textarea {
  background-color: var(--cx-bg-color-1);
  color: var(--cx-txt-color-1);
  border-color: var(--cx-border-color-2);
}

.cx-widget.cx-theme-booking .cx-btn-default {
  background: none;
  border-color: var(--cx-border-color-1) !important;
  color: var(--cx-primary-color);
  background-color: var(--cx-bg-color-1);
}

.cx-widget.cx-theme-booking .cx-btn.cx-disabled {
  background: var(--cx-bg-color-3);
}

.cx-widget.cx-theme-booking .cx-widget .cx-btn {
  font-weight: 500;
}

.cx-widget.cx-theme-booking .cx-btn-primary,
.cx-widget.cx-theme-booking .cx-btn-primary.cx-submit[data-form-valid="true"] {
  cursor: pointer;
  color: var(--cx-txt-color-3);
  border-color: transparent !important;
  background: var(--cx-primary-color);
}

.cx-widget.cx-theme-booking .cx-btn-primary.cx-submit {
  cursor: not-allowed;
  color: var(--cx-bg-color-3);
  border-color: transparent !important;
  background: var(--cx-bg-color-6);
}

.cx-widget.cx-theme-booking .cx-btn-primary.cx-close-confirm {
  background: var(--cx-alert-color);
}

.cx-widget.cx-theme-booking .cx-btn-primary.cx-end-confirm {
  background: var(--cx-alert-color);
}

.cx-widget.cx-theme-booking .cx-button-group .cx-btn-primary:focus,
.cx-widget.cx-theme-booking .cx-btn-primary:focus {
  border-color: var(--cx-outline-color-1) !important;
}

.cx-widget.cx-theme-booking .cx-ac-dropdown-menu {
  background: var(--cx-bg-color-1);
  border-color: var(--cx-border-color-1) !important;
}

.cx-widget.cx-theme-booking .cx-ac-suggestion:hover,
.cx-widget.cx-theme-booking .cx-ac-suggestion.cx-active {
  background-color: var(--cx-primary-color);
  color: var(--cx-primary-color);
}

.cx-widget.cx-theme-booking ::-webkit-scrollbar-thumb {
  background: var(--cx-bg-color-3);
}

.cx-widget.cx-theme-booking .cx-smokescreen {
  background-color: var(--cx-bg-color-1);
  opacity: 0.7;
}

.cx-widget.cx-theme-booking .cx-dialog-container .cx-dialog {
  background-color: var(--cx-bg-color-1);
  border-color: var(--cx-border-color-1) !important;
}

.cx-widget.cx-theme-booking .cx-theme-background {
  background-color: var(--cx-bg-color-1);
}

.cx-widget.cx-theme-booking .cx-theme-border {
  border: 1px solid var(--cx-border-color-1) !important;
}

.cx-widget.cx-theme-booking .cx-theme-text {
  color: var(--cx-txt-color-1);
}

.cx-widget.cx-theme-booking .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
  fill: var(--cx-primary-color);
}

.cx-widget.cx-theme-booking .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
  fill: var(--cx-success-color);
}

.cx-widget.cx-theme-booking .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
  fill: var(--cx-alert-color);
}

.cx-widget.cx-theme-booking .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
  fill: var(--cx-primary-color);
}

.cx-widget.cx-theme-booking .cx-warning {
  color: var(--cx-alert-color) !important;
}

.cx-widget.cx-theme-booking .cx-warning .cx-icon svg * {
  fill: var(--cx-alert-color) !important;
}

.cx-widget.cx-theme-booking .cx-countdown {
  color: var(--cx-txt-color-1);
}

.cx-widget.cx-theme-booking .cx-countdown .cx-icon svg * {
  fill: var(--cx-txt-color-1);
}

.cx-widget.cx-theme-booking .cx-webchat {
  width: 360px;
}

.cx-widget.cx-theme-booking .cx-webchat.cx-mobile {
  width: 400px !important;
  inset-inline-start: 50% !important;
  margin-inline-start: -200px;
}

.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-you .cx-bubble {
  background-color: var(--cx-bg-color-2);
  color: var(--cx-txt-color-3);
}

.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-you .cx-bubble .cx-name,
.cx-widget.cx-theme-booking
  .cx-transcript
  .cx-message-group
  > .cx-message.cx-participant.cx-you
  .cx-bubble
  .cx-message-text,
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-you .cx-bubble .cx-time {
  color: var(--cx-txt-color-3);
}

.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-you .cx-bubble-arrow svg {
  fill: var(--cx-bg-color-2);
}

.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble {
  background-color: #ededed;
  color: var(--cx-txt-color-1);
}

.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble .cx-name,
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble .cx-message-text,
.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble .cx-time {
  color: var(--cx-txt-color-1);
}

.cx-widget.cx-theme-booking .cx-transcript .cx-message-group > .cx-message.cx-participant .cx-bubble-arrow svg {
  fill: #ededed;
}

.cx-widget.cx-theme-booking .cx-button-group.cx-buttons-window-control button.cx-icon.cx-button-minimize {
  display: none;
}

.cx-widget.cx-theme-booking .cx-input-container {
  font-size: 12px;
}

.cx-widget.cx-theme-booking .cx-input-container .cx-textarea-cell textarea.cx-message-input.cx-input {
  overflow: auto;
  font-size: 11px !important;
  line-height: 12px !important;
}

.cx-widget.cx-theme-booking .cx-webchat.cx-mobile .cx-form.cx-form-horizontal {
  padding: 18px 16px;
}

.cx-widget.cx-theme-booking .cx-webchat .cx-form table td {
  padding: 6px 0;
}

.cx-widget.cx-theme-booking input.cx-input[disabled] {
  background: #f2f2f2;

  /* padding: 24px 12px; */
}

.cx-widget.cx-theme-booking label[for="cx_webchat_welcome"] {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.cx-widget.cx-theme-booking label[for="cx_webchat_form_consent"],
.cx-widget.cx-theme-booking #consent-privacy-policy-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.cx-widget.cx-theme-booking input#cx_webchat_form_consent {
  margin-block-start: 4px;
}

.cx-widget.cx-theme-booking input#cx_webchat_welcome {
  display: none;
}

.cx-widget.cx-theme-booking .cx-webchat.cx-mobile .cx-footer {
  display: none !important;
}

.cx-widget.cx-theme-booking #cx_chat_end_question,
.cx-widget.cx-theme-booking #cx_chat_confirm_close {
  margin: 16px 0;
}
