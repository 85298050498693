.modal footer {
  padding-block-start: var(--bui_spacing_4x);
  padding-block-end: var(--bui_spacing_4x);
}

.mobileModal {
  height: 100%;
  padding: 0;
}

.mobileModal .bui-modal__inner {
  height: 100%;
}

.mobileModal .bui-modal__body {
  padding: 0;
}

.mobileModal .bui-modal__header,
.mobileModal .bui-modal__footer {
  padding: var(--bui_spacing_4x);
}

.mobileModal .bui-modal__header button,
.mobileModal .bui-modal__footer button {
  width: calc(100% - 8px);
}

.viewBreakdownLink {
  color: var(--bui_color_action_foreground);
  text-decoration: none;
  margin-block-end: var(--bui_spacing_2x);
  display: inline-block;
  text-align: start;
}
