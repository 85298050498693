.carrierLogo {
  width: var(--bui_spacing_4x);
  height: var(--bui_spacing_4x);
  background-size: contain;
  overflow: hidden;
}

.logoMargin {
  width: var(--bui_spacing_4x);
  height: var(--bui_spacing_4x);
  margin-inline-end: var(--bui_spacing_2x);
}

.singleLogo {
  width: var(--bui_spacing_8x);
  height: var(--bui_spacing_8x);
  display: block;
  background-size: contain;
  overflow: hidden;
}

.separator {
  white-space: pre;
}

.grid {
  display: grid;
  grid-gap: var(--bui_spacing_1x);
  grid-template-columns: [col1] 16px [col2] 16px;
  grid-template-rows: [row1] 16px [row2] 16px;
}
