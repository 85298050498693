.wrapper {
  width: 100%;
  display: grid;
  grid-gap: var(--bui_spacing_1x);
}

.line {
  width: 100%;
  display: grid;
  grid-gap: var(--bui_spacing_1x);
  align-content: stretch;
}

.destination {
  display: grid;
}

.date {
  width: 100%;
}

.remove {
  grid-column: 3;
}
