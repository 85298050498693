.shell {
  cursor: pointer;
}

.popovercontainer {
  max-width: 550px;
  min-width: 550px;
}

.popovercontainerFareCalendar {
  max-width: 642px;
  min-width: 642px;
}

.btn {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
  text-align: initial;
  cursor: pointer;
}

.btn:active,
.btn:focus {
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
}

.calendarWrapper {
  display: grid;
  grid-gap: var(--bui_spacing_4x);
}

.calendarWrapperInner {
  padding: var(--bui_spacing_4x);
}
