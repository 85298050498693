.travelAlert {
  background-color: var(--bui_color_callout_background_alt);
  border: 1px solid var(--bui_color_callout_border);
  padding: var(--bui_spacing_4x);
  color: var(--bui_color_foreground);
  border-radius: var(--bui_border_radius_200);
}

.travelAlertLink {
  color: var(--bui_color_action_foreground);
}
