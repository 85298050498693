.justify-content_flex-start {
  display: flex;
  justify-content: flex-start;
}

.justify-content_flex-end {
  display: flex;
  justify-content: flex-end;
}

.justify-content_start {
  display: flex;
  justify-content: flex-start;
}

.justify-content_end {
  display: flex;
  justify-content: flex-end;
}

.justify-content_left {
  display: flex;
  justify-content: left;
}

.justify-content_right {
  display: flex;
  justify-content: right;
}

.justify-content_center {
  display: flex;
  justify-content: center;
}

.justify-content_space-around {
  display: flex;
  justify-content: space-around;
}

.justify-content_space-between {
  display: flex;
  justify-content: space-between;
}

.align-items_flex-start {
  display: flex;
  align-items: flex-start;
}

.align-items_flex-end {
  display: flex;
  align-items: flex-end;
}

.align-items_start {
  display: flex;
  align-items: flex-start;
}

.align-items_end {
  display: flex;
  align-items: flex-end;
}

.align-items_top {
  display: flex;
  align-items: top;
}

.align-items_bottom {
  display: flex;
  align-items: bottom;
}

.align-items_center {
  display: flex;
  align-items: center;
}

.align-items_stretch {
  display: flex;
  align-items: stretch;
}

.align-items_baseline {
  display: flex;
  align-items: baseline;
}

.align-items_left {
  display: flex;
  align-items: left;
}

.align-items_right {
  display: flex;
  align-items: right;
}

.flex-wrap_wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-wrap_nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.flex-wrap_wrap-reverse {
  display: flex;
  flex-wrap: wrap-reverse;
}

.flex-direction_column {
  display: flex;
  flex-direction: column;
}

.flex-direction_column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex-direction_row {
  display: flex;
  flex-direction: row;
}

.flex-direction_row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-grow_0 {
  display: flex;
  flex-grow: 0;
}

.flex-grow_1 {
  display: flex;
  flex-grow: 1;
}

.flex-grow_2 {
  display: flex;
  flex-grow: 2;
}

.flex-grow_3 {
  display: flex;
  flex-grow: 3;
}

.flex-grow_4 {
  display: flex;
  flex-grow: 4;
}

.flex-grow_5 {
  display: flex;
  flex-grow: 5;
}

.margin_0 {
  margin: 0px;
}

.margin-top_0 {
  margin-block-start: 0px;
}

.margin-right_0 {
  margin-inline-end: 0px;
}

.margin-bottom_0 {
  margin-block-end: 0px;
}

.margin-left_0 {
  margin-inline-start: 0px;
}

.margin_05 {
  margin: var(--bui_spacing_half);
}

.margin-top_05 {
  margin-block-start: var(--bui_spacing_half);
}

.margin-right_05 {
  margin-inline-end: var(--bui_spacing_half);
}

.margin-bottom_05 {
  margin-block-end: var(--bui_spacing_half);
}

.margin-left_05 {
  margin-inline-start: var(--bui_spacing_half);
}

.margin_1 {
  margin: var(--bui_spacing_1x);
}

.margin-top_1 {
  margin-block-start: var(--bui_spacing_1x);
}

.margin-right_1 {
  margin-inline-end: var(--bui_spacing_1x);
}

.margin-bottom_1 {
  margin-block-end: var(--bui_spacing_1x);
}

.margin-left_1 {
  margin-inline-start: var(--bui_spacing_1x);
}

.margin_2 {
  margin: var(--bui_spacing_2x);
}

.margin-top_2 {
  margin-block-start: var(--bui_spacing_2x);
}

.margin-right_2 {
  margin-inline-end: var(--bui_spacing_2x);
}

.margin-bottom_2 {
  margin-block-end: var(--bui_spacing_2x);
}

.margin-left_2 {
  margin-inline-start: var(--bui_spacing_2x);
}

.margin_3 {
  margin: var(--bui_spacing_3x);
}

.margin-top_3 {
  margin-block-start: var(--bui_spacing_3x);
}

.margin-right_3 {
  margin-inline-end: var(--bui_spacing_3x);
}

.margin-bottom_3 {
  margin-block-end: var(--bui_spacing_3x);
}

.margin-left_3 {
  margin-inline-start: var(--bui_spacing_3x);
}

.margin_4 {
  margin: var(--bui_spacing_4x);
}

.margin-top_4 {
  margin-block-start: var(--bui_spacing_4x);
}

.margin-right_4 {
  margin-inline-end: var(--bui_spacing_4x);
}

.margin-bottom_4 {
  margin-block-end: var(--bui_spacing_4x);
}

.margin-left_4 {
  margin-inline-start: var(--bui_spacing_4x);
}

.margin_6 {
  margin: var(--bui_spacing_6x);
}

.margin-top_6 {
  margin-block-start: var(--bui_spacing_6x);
}

.margin-right_6 {
  margin-inline-end: var(--bui_spacing_6x);
}

.margin-bottom_6 {
  margin-block-end: var(--bui_spacing_6x);
}

.margin-left_6 {
  margin-inline-start: var(--bui_spacing_6x);
}

.margin_8 {
  margin: var(--bui_spacing_8x);
}

.margin-top_8 {
  margin-block-start: var(--bui_spacing_8x);
}

.margin-right_8 {
  margin-inline-end: var(--bui_spacing_8x);
}

.margin-bottom_8 {
  margin-block-end: var(--bui_spacing_8x);
}

.margin-left_8 {
  margin-inline-start: var(--bui_spacing_8x);
}

.padding_0 {
  padding: 0px;
}

.padding-top_0 {
  padding-block-start: 0px;
}

.padding-right_0 {
  padding-inline-end: 0px;
}

.padding-bottom_0 {
  padding-block-end: 0px;
}

.padding-left_0 {
  padding-inline-start: 0px;
}

.padding_05 {
  padding: var(--bui_spacing_half);
}

.padding-top_05 {
  padding-block-start: var(--bui_spacing_half);
}

.padding-right_05 {
  padding-inline-end: var(--bui_spacing_half);
}

.padding-bottom_05 {
  padding-block-end: var(--bui_spacing_half);
}

.padding-left_05 {
  padding-inline-start: var(--bui_spacing_half);
}

.padding_1 {
  padding: var(--bui_spacing_1x);
}

.padding-top_1 {
  padding-block-start: var(--bui_spacing_1x);
}

.padding-right_1 {
  padding-inline-end: var(--bui_spacing_1x);
}

.padding-bottom_1 {
  padding-block-end: var(--bui_spacing_1x);
}

.padding-left_1 {
  padding-inline-start: var(--bui_spacing_1x);
}

.padding_2 {
  padding: var(--bui_spacing_2x);
}

.padding-top_2 {
  padding-block-start: var(--bui_spacing_2x);
}

.padding-right_2 {
  padding-inline-end: var(--bui_spacing_2x);
}

.padding-bottom_2 {
  padding-block-end: var(--bui_spacing_2x);
}

.padding-left_2 {
  padding-inline-start: var(--bui_spacing_2x);
}

.padding_3 {
  padding: var(--bui_spacing_3x);
}

.padding-top_3 {
  padding-block-start: var(--bui_spacing_3x);
}

.padding-right_3 {
  padding-inline-end: var(--bui_spacing_3x);
}

.padding-bottom_3 {
  padding-block-end: var(--bui_spacing_3x);
}

.padding-left_3 {
  padding-inline-start: var(--bui_spacing_3x);
}

.padding_4 {
  padding: var(--bui_spacing_4x);
}

.padding-top_4 {
  padding-block-start: var(--bui_spacing_4x);
}

.padding-right_4 {
  padding-inline-end: var(--bui_spacing_4x);
}

.padding-bottom_4 {
  padding-block-end: var(--bui_spacing_4x);
}

.padding-left_4 {
  padding-inline-start: var(--bui_spacing_4x);
}

.padding_6 {
  padding: var(--bui_spacing_6x);
}

.padding-top_6 {
  padding-block-start: var(--bui_spacing_6x);
}

.padding-right_6 {
  padding-inline-end: var(--bui_spacing_6x);
}

.padding-bottom_6 {
  padding-block-end: var(--bui_spacing_6x);
}

.padding-left_6 {
  padding-inline-start: var(--bui_spacing_6x);
}

.padding_8 {
  padding: var(--bui_spacing_8x);
}

.padding-top_8 {
  padding-block-start: var(--bui_spacing_8x);
}

.padding-right_8 {
  padding-inline-end: var(--bui_spacing_8x);
}

.padding-bottom_8 {
  padding-block-end: var(--bui_spacing_8x);
}

.padding-left_8 {
  padding-inline-start: var(--bui_spacing_8x);
}
