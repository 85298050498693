.floatingPanel {
  position: absolute;
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  background-color: var(--bui_color_background_base);
  z-index: 500;
  transition: transform 0.5s;
  transform: translateY(0%);
}

.sticky {
  position: sticky;
  inset-block-end: 0px;
}
