.timeDateBlock {
  width: 70px;
  white-space: nowrap;
  flex: 1;

  .iataAndDate {
    color: var(--bui_color_foreground_alt);

    .iataDateSeparator {
      white-space: pre;
    }

    .iataWarning {
      background: var(--bui_color_accent_background_alt);
      border-radius: var(--bui_border_radius_100);
      padding: var(--bui_spacing_half) var(--bui_spacing_half);
      margin: calc(var(--bui_spacing_half) * -1);
    }
  }
}

.stopsTextStyle {
  color: var(--bui_color_foreground);
}

.stopsRoot {
  width: 140px;

  .stopsWrap {
    position: relative;
    width: 90%;
    text-align: center;

    .stopsConnector {
      position: absolute;
      border-block-start: 1px solid var(--bui_color_foreground);
      width: 100%;
      inset-inline-start: 0;
      inset-block-start: 50%;
      z-index: 1;

      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        border: 1px solid var(--bui_color_foreground);
        border-radius: var(--bui_border_radius_rounded);
        inset-block-start: -5px;
      }

      &::before {
        inset-inline-start: -8px;
      }

      &::after {
        inset-inline-end: -8px;
      }
    }

    .stopsBadge {
      position: relative;
      z-index: 2;
    }
  }
}
