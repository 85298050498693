.bannerRoot {
  margin-block-end: var(--bui_spacing_4x);
}

.modalContent {
  margin: var(--bui_spacing_6x);
}

[data-device="mdot"] .modalContent {
  margin: var(--bui_spacing_4x);
}

.modalTitle {
  margin-block-end: var(--bui_spacing_4x);
  margin-inline-end: var(--bui_spacing_8x);
}

.modalParagraph {
  margin-block-end: var(--bui_spacing_4x);
}

[data-device="mdot"] .modalCtaButton {
  width: 100%;
}
