.segment {
  margin-block-start: var(--bui_spacing_4x);
}

.segment:first-child {
  margin-block-start: 0px;
}

.segmentCarriers {
  width: 15%;
}

.segmentDetails {
  width: 85%;
}

.segmentCarriersV2 {
  width: 46px;
}

.segmentDetailsV2 {
  width: calc(100% - 60px);
}

.priceBlock {
  width: 36%;
  border-inline-start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
  margin: calc(var(--bui_spacing_4x) * -1) 0;
  margin-inline-start: var(--bui_spacing_4x);
  padding: var(--bui_spacing_4x);
  padding-inline-end: 0px;
}

.priceBlockWider {
  width: 44%;
}

.button {
  margin-block-start: var(--bui_spacing_4x);
  height: 36px;
  padding-block-start: var(--bui_spacing_1x);
  width: 100%;
}

.noMarginButton {
  margin-block-start: var(--bui_spacing_2x);
  padding-block-start: var(--bui_spacing_1x);
  width: 100%;
}

.buttonSwapPriceBaggage {
  margin-block-start: 0;
  height: 36px;
  padding-block-start: var(--bui_spacing_1x);
  width: 100%;
}

.showFaresButton {
  margin-block-start: var(--bui_spacing_1x);
}

.showFaresButtonWithMargin {
  margin-block-start: var(--bui_spacing_2x);
}

.hidden {
  visibility: hidden;
}

.tripsSavingTag {
  @apply --bui_font_small_1;
  background: var(--bui_color_constructive_background_alt);
  color: var(--bui_color_constructive_foreground);
  margin: var(--bui_spacing_4x);
  margin-block-end: 0px;
  padding: var(--bui_spacing_1x) var(--bui_spacing_2x);
  display: inline-block;
}

.arrow {
  transform: rotate(180deg);
}

.divider {
  border-block-end: 1px solid var(--bui_color_border_alt);
}

.cardShadow {
  box-shadow: var(--bui_shadow_100);
}

.buttonArea {
  margin-block-start: var(--bui_spacing_4x);
}

.highlightFirefly {
  border-color: var(--bui_color_brand_primary_background);
  border-width: 2px;
}

.badgesWithBottomMargin {
  margin-block-end: var(--bui_spacing_4x);
}
