.loading {
  text-align: center;
}

.loading > div {
  margin-block-end: var(--bui_spacing_4x);
}

.searchResults {
  padding-block-end: var(--bui_spacing_4x);
  position: relative;
}

.searchResultsList {
  margin-block-start: calc(var(--bui_spacing_half) * -1);
}

.paginationItem {
  display: inline-block;
  cursor: pointer;
  padding-inline-start: var(--bui_spacing_2x);
  padding-inline-end: var(--bui_spacing_2x);
}

.noResults {
  @apply --bui_font_emphasized_2;
  text-align: center;
  color: var(--bui_color_foreground_alt);
  margin: var(--bui_spacing_8x);
}

.searchRemoveIcon {
  margin-block-end: var(--bui_spacing_4x);
}
