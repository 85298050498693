.group {
  display: inline-grid;
  font-size: 0;
  line-height: 0;
  gap: var(--bui_spacing_1x);
}

.group__inline {
  grid-auto-flow: column;
  grid-template-columns: unset;
  grid-template-rows: unset;
}

.group__inline_no_gap {
  gap: 0;
}

.group__grid {
  grid-auto-flow: column;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}

.group__grid__last {
  grid-column: 2;
  grid-row: 2;
}
