.container {
  min-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(1140px - var(--bui_spacing_8x) * 2);
}

/* stylelint-disable-next-line bui-media-queries/validate-media */
@media (max-width: 350px) {
  .container {
    min-width: auto;
  }
}

.container > div {
  width: 100%;
}
