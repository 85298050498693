.shell {
  cursor: pointer;
}

.btn {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
  text-align: initial;
  cursor: pointer;
}

.btn:active,
.btn:focus {
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
}

.container {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  padding: var(--bui_spacing_4x);
  margin: calc(var(--bui_spacing_4x) * -1);
}

.input {
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
  width: 100%;
  text-align: initial;
  display: block;
  white-space: nowrap;
  max-width: 100%;
}

.input > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.input:active,
.input:focus {
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
}
