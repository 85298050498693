.result {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: streach;
  gap: var(--bui_spacing_1x);
}

.location {
  display: flex;
  flex-direction: row;
  gap: var(--bui_spacing_2x);
  padding: var(--bui_spacing_2x);
  border-radius: var(--bui_border_radius_100);
  cursor: pointer;
}

.location:focus {
  border-color: none;
  outline: none;
  box-shadow: inset 0 0 0 2px var(--bui_color_action_border);
}

[data-bui-keyboard] .location:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base),
    0 0 0 4px var(--bui_color_action_border);
}

.spacer {
  min-width: var(--bui_spacing_2x);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: streach;
  flex: 1;
}

.icon {
  padding-block-start: var(--bui_spacing_half);
}

.checkBox {
  padding-block-start: var(--bui_spacing_half);
}
