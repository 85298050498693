.content {
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  padding: var(--bui_spacing_3x) 0 0;
}

.contentScrollArea {
  overflow: scroll;
  flex: 1;
  padding: var(--bui_spacing_1x) var(--bui_spacing_4x);
}

.contentFooter {
  padding: 0px;
}
