.item {
  display: inline-block;
  padding: var(--bui_spacing_half);
  background-color: var(--bui_color_background_disabled_alt);
  border-radius: var(--bui_border_radius_100);
  white-space: nowrap;
  cursor: pointer;
  border-style: solid;
  border-width: var(--bui_border_width_100);
  border-color: var(--bui_color_border);
}

.text {
  display: inline-block;
  padding: 0 var(--bui_spacing_1x);
}

.icon {
  display: inline-block;
  vertical-align: text-bottom;
}

.icon,
.icon > svg {
  width: 16px;
  height: 16px;
}
