/* stylelint-disable-next-line bui-optimal-typography/validate-typography */
.wrapper {
  display: inline-block;
  background: none;
  color: inherit;
  border-width: 0px;
  padding: var(--bui_spacing_half);
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: relative;
}

[data-bui-keyboard] .wrapper:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base), 0 0 0 4px var(--bui_color_action_border);
  border-radius: var(--bui_border_radius_100);
}

.wrapper * {
  display: inline-block;
  vertical-align: top;
}

.error {
  border-block-end: solid var(--bui_border_width_100) var(--bui_color_destructive_border) !important;
  border-block-end-width: var(--bui_border_width_100);
}

.errorPointer {
  position: absolute;
  width: 0px;
  height: 0px;
}
