.sticky {
  position: sticky;
  inset-block-start: var(--bui_spacing_4x);
}

.innerScroll {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - var(--bui_spacing_4x));
}
