.root {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  height: 100%;
  grid-gap: 0 var(--bui_spacing_2x);
  background-color: var(--bui_color_background_elevation_one);
  border-radius: var(--bui_border_radius_100);
  padding: var(--bui_spacing_2x);
  box-sizing: border-box;
  min-height: calc(var(--bui_spacing_8x) + var(--bui_spacing_4x));
}

.rootfocus {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--bui_color_action_border);
}

[data-bui-keyboard] .rootfocus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bui_color_background_base),
    0 0 0 4px var(--bui_color_action_border);
  z-index: 2;
}

.rootfocus * {
  box-shadow: none !important;
}

.rootError {
  border: solid var(--bui_border_width_100) var(--bui_color_destructive_border);
}

.rootFill {
  padding: 0 !important;
}

.rootBorder {
  border: solid var(--bui_border_width_100) var(--bui_color_border_alt);
}

.rootLarge {
  min-height: calc(var(--bui_spacing_8x) + var(--bui_spacing_6x));
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  grid-gap: var(--bui_spacing_2x);
}

.content {
  width: 100%;
  display: grid;
  position: relative;
}

.contentPrefix {
  grid-column: -3;
  display: inline-block;
}

.contentSuffix {
  grid-column: 2;
  display: inline-block;
}
