.stops_no_connector {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.stops__content {
  display: flex;
  align-items: center;
  gap: 0;
  font-size: 0;
  line-height: 0;
}

.stops__content::after,
.stops__content::before {
  content: " ";
  display: inline-block;
  width: var(--bui_spacing_2x);
  height: var(--bui_spacing_2x);
  border-radius: var(--bui_spacing_2x);
  border: var(--bui_border_width_100) solid var(--bui_color_foreground);
  box-sizing: border-box;
}

.stops__inner {
  display: flex;
  flex: 1;
  flex-direction: rows;
  align-items: center;
}

.stops_no_connector_inner {
  align-items: end;
}

.stops__inner::after,
.stops__inner::before {
  content: " ";
  display: block;
  flex: 1;
  border-block-start: 1px solid var(--bui_color_foreground);
}

.stops__text {
  display: inline-block;
  border: var(--bui_border_width_100) solid var(--bui_color_border);
  border-radius: var(--bui_border_radius_100);
  padding: var(--bui_spacing_half) var(--bui_spacing_1x);
}

.stops__text__constructive {
  background-color: var(--bui_color_constructive_background);
  border-color: var(--bui_color_constructive_background);
}

.stops__subtext {
  text-align: center;
}
