.wrapper {
  display: inline-block;
}

.darkRadio label > span:nth-child(2) {
  background: var(--bui_color_brand_primary_background);
  border-color: var(--bui_color_background_base) !important;
  border-width: var(--bui_border_width_200);
  box-shadow: none !important;
}

.darkRadio label > span:nth-child(2)::before {
  background-color: var(--bui_color_background_base) !important;
}

.focusedRadio label > span:nth-child(2) {
  border-color: var(--bui_color_accent_background) !important;
}
