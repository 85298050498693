.root {
  display: flex;
  flex-direction: column;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.titleText {
  @apply --bui_font_strong_2;
}

.listItemDetails {
  color: var(--bui_color_foreground_alt);
}

.listItemComment {
  color: var(--bui_color_foreground_alt);
}

.listItemMoney {
  white-space: nowrap;
  color: var(--bui_color_foreground);
}

.discountAlert {
  margin-block-start: var(--bui_spacing_2x);
}

.colorTaxHeader {
  color: var(--bui_color_foreground);
}

.textColorHeader {
  color: var(--bui_color_foreground);
}
