.questionIcon {
  fill: var(--bui_color_black);
}

.sheetStyles {
  padding-block-end: var(--bui_spacing_8x);
  inset-inline-start: 0;
}

.popoverTitle {
  padding-block-end: var(--bui_spacing_4x);
}

.drawer {
  position: absolute;
  inset-block-end: 0;
  padding-block-start: var(--bui_spacing_2x);
}

.popover {
  padding: var(--bui_spacing_6x) var(--bui_spacing_4x) var(--bui_spacing_4x);
  width: 360px;
}

.breakDownInfoIcon {
  margin-block-start: var(--bui_spacing_1x);
  margin-inline-start: var(--bui_spacing_2x);
}

.breakDownIconWrapper {
  margin-block-start: auto;
  margin-block-end: auto;
}

.breakDownIconWrapper > span {
  margin-block-start: 0;
}

[data-device="mdot"] .breakDownIcon {
  margin-inline-start: var(--bui_spacing_1x);
}

[data-device="desktop"] .breakDownIcon {
  margin-inline-start: var(--bui_spacing_2x);
}

.mainPrice {
  white-space: nowrap;
}

[data-device="desktop"] .mainPrice {
  padding-block-start: var(--bui_spacing_half);
}

.priceContainer {
  display: inline-flex;
}

.strikedPrice {
  display: block;
  margin-inline-end: var(--bui_spacing_1x);
  white-space: pre;
}

.strikedPriceFD {
  position: relative;
  inset-block-start: var(--bui_spacing_2x);
  margin-inline-start: var(--bui_spacing_1x);
}

.strikedPriceOnTop {
  margin-inline-end: 0;
}

.offsetBreakdownMargin {
  margin-block-end: calc(var(--bui_spacing_2x) * -1);
}

.priceInline {
  display: inline;
}
