.yellowInner {
  display: grid;
  grid-template-rows: 1fr;
  align-content: stretch;
  grid-gap: var(--bui_spacing_1x);
  transition: all 0.24s ease-in;
  position: relative;
  opacity: 1;
}

.yellowInnerFadeOut {
  position: absolute;
  opacity: 0;
  inset-inline-start: var(--bui_spacing_1x);
  inset-inline-end: var(--bui_spacing_1x);
}

.overview {
  padding-block-start: var(--bui_spacing_1x) !important;
  padding-block-end: var(--bui_spacing_1x) !important;
}
