.titleOneColumn {
  margin-block-end: var(--bui_spacing_2x);
}

.titleTwoColumn {
  width: 200px;
  padding-inline-end: var(--bui_spacing_4x);
  border-inline-end: 1px solid var(--bui_color_border_alt);
  margin-inline-end: var(--bui_spacing_4x);
}

.divider {
  padding-block-start: var(--bui_spacing_4x);
  border-block-start: 1px solid var(--bui_color_border_alt);
}
