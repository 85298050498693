.wrapper {
  display: grid;
  grid-gap: var(--bui_spacing_2x);
}

/* stylelint-disable-next-line bui-optimal-typography/validate-typography */
.inputInner {
  display: flex;
  flex-direction: row;
  gap: var(--bui_spacing_1x);
  flex-wrap: wrap;
  -ms-flex-direction: column;
}

.textInput {
  border-width: 0px;
  outline: none;
  margin: 0px;
  flex: 1;
  min-width: 100px;
  background: none;

  @apply --bui_font_body_2;
}

.textInput:active,
.textInput:focus {
  border-width: 0px;
  outline: none;
}

.textInput::placeholder {
  color: var(--bui_color_foreground_alt);
}

.textInputLarge {
  @apply --bui_font_body_1;
}
