.wrapper {
  position: sticky;
  inset-block-start: 0px;
  z-index: 99;
  background-color: var(--bui_color_background_base);
}

.toolbar {
  width: 100vw;
  height: 52px;
}

.toolbarBg {
  background-color: var(--bui_color_background_base);
}

.button {
  padding: var(--bui_spacing_4x);
  padding-block-start: var(--bui_spacing_2x);
}

.resetAllLink {
  float: right;
}

[dir="rtl"] .resetAllLink {
  float: left;
}

.modalContent {
  padding: var(--bui_spacing_4x);
  overflow: hidden;
}
