.wrapper {
  width: 44px;
  height: 44px;
  display: grid;
}

.logo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  border-radius: var(--bui_border_radius_100);
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2px;
}

.grid [data-ui-logo-container]:last-child {
  grid-area: 2 / 2;
}
