.wrapper {
  display: grid;
  grid-gap: var(--bui_spacing_4x);
  height: 100%;
  grid-template-columns: 1fr;
  align-content: start;
}

.wrapperHorizontal {
  grid-template-columns: 1fr 1fr;
}

.content {
  display: grid;
  grid-gap: var(--bui_spacing_4x);
  align-content: start;
}
