.illustrationContainer {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border-start-start-radius: var(--bui_border_radius_300);
  border-start-end-radius: var(--bui_border_radius_300);
  background-color: var(--bui_color_background_base_alt);
  overflow: hidden;
}

.illustrationContainer_white {
  background-color: var(--bui_color_white);
}

.illustrationContainer svg {
  height: 60vh;
}

.button {
  width: 350px;
  height: var(--bui_spacing_12x);

  /* stylelint-disable-next-line bui-optimal-typography/validate-typography */
  line-height: var(--bui_font_small_2_line-height);
  margin-block-start: var(--bui_spacing_3x) !important;
}
